import React from "react";
import "./History.css";
import Emoji from "./Emoji";

const History = () => {
  return (
    <div className="historyContainer">
      <div className="yearContainer">
        <h1>Desde 2017</h1>
      </div>
      <div className="statistics">
        <div className="numberContainer">
          <h3>Hemos generado</h3>
          <div className="numberInfo">
            <p>
              <strong style={{ fontSize: "1.5rem" }}>+33.000.000</strong> links{" "}
              <Emoji symbol="🎉" size={25} />
            </p>
          </div>
        </div>
        <div className="numberContainer">
          <h3>Servimos al mes</h3>
          <div className="numberInfo">
            <p>
              <strong style={{ fontSize: "1.5rem" }}>+150.000.000</strong> de
              chats <Emoji symbol="🤯" size={25} />
            </p>
          </div>
        </div>
        <div className="numberContainer">
          <h3>Creamos diariamente</h3>
          <div className="numberInfo">
            <p>
              <strong style={{ fontSize: "1.5rem" }}>+45.000</strong> links{" "}
              <Emoji symbol="📈" size={25} />
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default History;

import React from 'react'
import worldmap from '../images/world-map.svg'
import './Countries.css'

const Countries = () => {
  return (
    <div className="countriesContainer">
      <h1>En 180 países</h1>
      <img src={worldmap} className="worldMap" alt="world map" />
    </div>
  )
}

export default Countries

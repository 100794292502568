import React from 'react'
import Emoji from './Emoji'
import './Benefits.css'

const Benefits = () => {
  return (
    <div className="benefitsContainer">
      <div className="benefits">
        <div className="benefitCard">
          <h3>
            <Emoji symbol="🤩" size={25} /> Conversión
          </h3>
          <p>
            Cuando un usuario ve un <strong>wa.link </strong>sabe que va a abrir
            una <strong>conversación de WhatsApp</strong>, aumentando la
            conversión para el negocio.
          </p>
        </div>
        <div className="benefitCard">
          <h3>
            <Emoji symbol="🔗" size={25} /> Shortlink
          </h3>
          <p>
            Los links <strong>incluyen el mensaje personalizado </strong>sin
            afectar la extensión o presentación del link.
          </p>
        </div>

        <div className="benefitCard">
          <h3>
            <Emoji symbol="️🕵️" size={25} /> Privacidad
          </h3>
          <p>
            La privacidad es importante, por eso, el link{' '}
            <strong>no expone tu número</strong>, ni mensaje de forma pública en
            Internet sin tu consentimiento.
          </p>
        </div>
        <div className="benefitCard">
          <h3>
            <Emoji symbol="📱" size={25} /> Accesibilidad
          </h3>
          <p>
            Además del link, obtienes un <strong> código QR </strong>con la
            misma información de tu enlace.
          </p>
        </div>
      </div>
    </div>
  )
}

export default Benefits

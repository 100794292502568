import React from 'react'
import walinkLogo from '../images/logo-walink.svg'
import './Cover.css'

const Cover = () => {
  return (
    <div className="coverContainer">
      <img className="walinkLogo" src={walinkLogo} alt="walink logo" />
      <div className="title">
        <h1>Enterprise</h1>
      </div>
    </div>
  )
}

export default Cover

import React from "react";
import "./Example.css";

const Example = ({ url, img, title, description }) => {
  return (
    <div className="exampleCard">
      <h2>{title}</h2>
      <p>{description}</p>
      {img && (
        <img
          src={img}
          alt=""
          style={{ maxHeight: 350, objectFit: "contain" }}
        />
      )}
      {url && (
        <a href={url} target="_blank" rel="noopener noreferrer">
          {url.length > 35 ? url.substring(0, 35) + "..." : url}
        </a>
      )}
    </div>
  );
};

export default Example;

import React from 'react'
import './Description.css'
import Emoji from './Emoji'
import whatsappLogo from '../images/WhatsApp.svg'

const Description = () => {
  return (
    <div className="presentationContainer">
      <div className="description">
        <p>
          Walink es una plataforma web que permite a usuarios en todo el mundo
          crear un acceso rápido a su chat a través de{' '}
          <strong>links de Whatsapp.</strong>
          <br />
          <br />
          Piénsenlo como <u>dominios web para cuentas de WhatsApp</u>.{' '}
          <Emoji symbol="🤯" size={20} />
          {/* Somos una plataforma tecnológica dedicada a conectar clientes con
          negocios a través <strong>links de WhatsApp</strong>. */}
        </p>
      </div>
      <div className="whatsappLogoContainer">
        <img className="whatsappLogo" src={whatsappLogo} alt="WhatsApp Logo" />
      </div>
    </div>
  )
}

export default Description

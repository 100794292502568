import React from 'react'
import Emoji from './Emoji'
import './Benefits.css'

const Benefits = ({ linkName }) => {
  function upperCase(name) {
    if (name) {
      return name.toUpperCase()
    } else {
      return 'TUNEGOCIO'
    }
  }

  function lowerCase(name) {
    if (name) {
      return name.toLowerCase()
    } else {
      return 'tunegocio'
    }
  }

  function capitalized(name) {
    if (name) {
      return name.charAt(0).toUpperCase() + name.slice(1)
    } else {
      return 'TuNegocio'
    }
  }
  return (
    <div className="benefitsContainer">
      <div className="benefits">
        <div className="benefitCard">
          <h3>
            <Emoji symbol="💬" size={25} /> Prioridad
          </h3>
          <p>
            Si tu número no está expuesto garantizas que un usuario siempre
            abrirá <strong>primero un chat de WhatsApp </strong>antes que
            llamarte.
          </p>
        </div>
        <div className="benefitCard">
          <h3>
            <Emoji symbol="📺" size={25} /> Omnicanalidad
          </h3>
          <p>
            Los links personalizados son perfectos para medios tradicionales
            (como piezas gráficas, radio, televisión, etc.), pues es más fácil{' '}
            <strong>recordar un nombre que un número de teléfono.</strong>
          </p>
        </div>

        <div className="benefitCard">
          <h3>
            <Emoji symbol="🎯" size={25} /> Certeza
          </h3>
          <p>
            Los links son case insensitive, es decir, un usuario podrá
            escribirte independientemente si usa:
            <br />
            <strong> wa.link/{capitalized(linkName)}</strong>
            <br />
            <strong> wa.link/{upperCase(linkName)}</strong>
            <br />
            <strong> wa.link/{lowerCase(linkName)}</strong>
          </p>
        </div>
        <div className="benefitCard">
          <h3>
            <Emoji symbol="⚙️" size={25} /> Modificable
          </h3>
          <p>
            A los links personalizados les puedes{' '}
            <strong>modificar el mensaje, el número de WhatsApp </strong>e
            incluso la URL, lo cual los hace versátiles para todo tipo de
            campañas.
          </p>
        </div>
      </div>
    </div>
  )
}

export default Benefits

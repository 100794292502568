import React from 'react'
import './ExplanationWhatsApp.css'
import Emoji from './Emoji'

const ExplanationWhatsApp = () => {
  return (
    <div className="whatsAppFactsContainer">
      <div className="factContainer">
        <h3>WhatsApp tiene</h3>
        <div className="factInfo">
          <p>
            <strong style={{ fontSize: '1.5rem' }}>+2B</strong> usuarios{' '}
            <Emoji symbol="😃" size={25} />
          </p>
        </div>
      </div>
      <div className="factContainer">
        <h3>Diariamente</h3>
        <div className="factInfo">
          <p>
            <strong style={{ fontSize: '1.5rem' }}>+175M</strong> mensajes{' '}
            <Emoji symbol="💬" size={25} />
          </p>
        </div>
      </div>
      <div className="factContainer">
        <h3>WhatsApp Business</h3>
        <div className="factInfo">
          <p>
            <strong style={{ fontSize: '1.5rem' }}>+50M</strong> negocios{' '}
            <Emoji symbol="💼" size={25} />
          </p>
        </div>
      </div>
    </div>
  )
}

export default ExplanationWhatsApp

import React from "react";
import Emoji from "./Emoji";

const PremiumLinks = ({ linkName }) => {
  return (
    <div className="serviceCard">
      <h2>
        Links Premium <Emoji symbol="👑" size={25} />
      </h2>
      <p>Para que todos reconozcan tu negocio en WhatsApp</p>
      <p>
        <Emoji symbol="✔️" /> Link personalizado, ej:{" "}
        <strong>wa.link/{linkName ? linkName : "TuNegocio"}</strong>
      </p>
      <p>
        <Emoji symbol="✔️" /> Analítica de clics
      </p>
      <p>
        <Emoji symbol="✔️" /> Editar URL, número y mensaje
      </p>
      <p className="price">
        <strong>$6 USD/año por link</strong>
      </p>
    </div>
  );
};

export default PremiumLinks;

import React from "react";
import Emoji from "./Emoji";

const MultiagentLinks = () => {
  return (
    <div className="serviceCard">
      <h2>
        Links Multiagente <Emoji symbol="🚀" size={25} />
      </h2>
      <p>Para crecer tus ventas sin límites</p>
      <p>
        <Emoji symbol="✔️" /> Todo lo incluido en Premium{" "}
      </p>
      <p>
        <Emoji symbol="🚀" />{" "}
        <strong>
          Distribuir los chats automáticamente entre multiples números de
          WhatsApp
        </strong>{" "}
      </p>
      <p className="price">
        <strong>$5 USD/mes por agente</strong>
      </p>
    </div>
  );
};

export default MultiagentLinks;

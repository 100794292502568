import React from "react";
import "./Explanation.css";
import Emoji from "./Emoji";

const Explanation = ({ linkName, phone }) => {
  return (
    <div className="explanationContainer">
      <div className="explanationDescription">
        <div>
          <p>
            Un <strong>link de WhatsApp</strong> te permite abrir una
            conversación con alguien sin necesidad de guardar su número
            telefónico
          </p>
          <div className="explanationExample">
            <p>
              <strong>Ejemplo: </strong>
              <a
                style={{
                  textDecoration: "none",
                  fontWeight: "bold",
                  fontSize: "1.5rem",
                }}
                target="_blank"
                rel="noopener noreferrer"
                href={
                  phone
                    ? `https://api.whatsapp.com/send?phone=${phone}&text=${encodeURIComponent(
                        "¡Hola " + linkName + "!"
                      )}`
                    : "https://crear.wa.link/premium"
                }
              >
                wa.link/{linkName ? linkName : "TuNegocio"}
              </a>
            </p>
          </div>
        </div>
      </div>
      <div className="explanationBullets">
        <div>
          <p>
            <Emoji symbol="✔️" /> Conversaciones inmediatas
          </p>
          <p>
            <Emoji symbol="✔️" /> Con un mensaje personalizado para que te
            saluden
          </p>
          <p>
            <Emoji symbol="✔️" /> Aumenta la conversión en tus canales digitales
            y tradicionales
          </p>
        </div>
      </div>
    </div>
  );
};

export default Explanation;

import React from "react";
import Emoji from "./Emoji";

const FreeLink = () => {
  return (
    <div className="servicesContainer">
      <div className="serviceCard">
        <h2>
          Links Gratis <Emoji symbol="👏" size={25} />
        </h2>
        <p>Pará usar en tus campañas y canales digitales.</p>
        <p>
          <Emoji symbol="✔️" /> Todos los links gratis que quieras
        </p>
        <p>
          <Emoji symbol="✔️" /> Link con URL acortada
        </p>
        <p>
          <Emoji symbol="✔️" /> Mensaje personalizado para que te saluden
        </p>
        <p className="price">
          <strong>$0 USD/forever</strong>
        </p>
      </div>
    </div>
  );
};

export default FreeLink;

import React, { useEffect, useState } from "react";
import Cover from "./components/Cover";
import Explanation from "./components/Explanation";
import ExplanationWhatsApp from "./components/ExplanationWhatsApp";
import History from "./components/History";
import Countries from "./components/Countries";
import Description from "./components/Description";
import Emoji from "./components/Emoji";
import FreeLinks from "./components/FreeLinks";
import PremiumLinks from "./components/PremiumLinks";
import Benefits1 from "./components/Benefits1";
import Benefits2 from "./components/Benefits2";
import MultiagentLinks from "./components/MultiagentLinks";
import FormLinks from "./components/FormLinks";
import { Swiper, Keyboard, Pagination, Scrollbar } from "swiper/js/swiper.esm";
import down from "./images/down.svg";
import "swiper/css/swiper.min.css";
import "./App.css";
// import Recommendations from "./components/Recommendations";
import Example from "./components/Example";
import smsExample from "./images/sms-walink-2.jpg";
import qrExample from "./images/qr-walink-eafit-2.jpg";
import eliRuiz from "./images/instagram-walink.jpeg";
import storeFront from "./images/qr-walink-storefront.jpeg";

function App() {
  const [swiper, setSwiper] = useState();
  const [end, setEnd] = useState(false);
  const [linkName, setLinkName] = useState("");
  const [phone, setPhone] = useState("");

  useEffect(() => {
    //check if query string paramethers were passed
    const params = new URLSearchParams(window.location.search);

    if (params.has("company")) {
      const name = params.get("company");
      if (name.length < 20) setLinkName(name);
    }
    if (params.has("phone")) {
      const phone = params.get("phone");
      if (phone.length < 20) setPhone(phone);
    }

    Swiper.use([Keyboard, Pagination, Scrollbar]);

    const mySwiper = new Swiper(".swiper-container", {
      speed: 600,

      // Optional parameters
      direction: "vertical",
      keyboard: {
        enabled: true,
      },

      // If we need pagination
      pagination: {
        el: ".swiper-pagination",
        type: "progressbar",
        progressbarOpposite: true,
      },
      mousewheel: true,
    });
    mySwiper.on("reachEnd", () => setEnd(true));
    mySwiper.on("fromEdge", () => setEnd(false));
    setSwiper(mySwiper);
  }, []);

  const nextSlide = () => {
    swiper.slideNext();
  };

  return (
    <>
      <div className="swiper-container">
        <div className="swiper-wrapper">
          <div className="swiper-slide">
            <div className="slide-content">
              <Cover />
            </div>
          </div>
          <div className="swiper-slide">
            <div className="slide-content">
              <h1>
                ¿Qué es Walink? <Emoji symbol="🤔" size={30} />
              </h1>
            </div>
          </div>
          <div className="swiper-slide">
            <div className="slide-content">
              <Description />
            </div>
          </div>
          <div className="swiper-slide">
            <div className="slide-content">
              <h1>¿Qué es un link de WhatsApp?</h1>
            </div>
          </div>
          <div className="swiper-slide">
            <div className="slide-content">
              <Explanation linkName={linkName} phone={phone} />
            </div>
          </div>
          <div className="swiper-slide">
            <div className="slide-content">
              <h1>¿Por qué WhatsApp?</h1>
            </div>
          </div>
          <div className="swiper-slide">
            <div className="slide-content">
              <ExplanationWhatsApp />
            </div>
          </div>
          <div className="swiper-slide">
            <div className="slide-content">
              <h1>¿Por qué usar Walink?</h1>
            </div>
          </div>
          <div className="swiper-slide">
            <div className="slide-content">
              <Benefits1 />
            </div>
          </div>
          <div className="swiper-slide">
            <div className="slide-content">
              <Benefits2 linkName={linkName} />
            </div>
          </div>
          <div className="swiper-slide">
            <div className="slide-content">
              <h1>¿Qué tanto se usan?</h1>
            </div>
          </div>
          <div className="swiper-slide">
            <div className="slide-content">
              <History />
            </div>
          </div>
          <div className="swiper-slide">
            <div className="slide-content">
              <Countries />
            </div>
          </div>
          <div className="swiper-slide">
            <div className="slide-content">
              <h1>Ejemplos de uso:</h1>
            </div>
          </div>
          <div className="swiper-slide">
            <div className="slide-content">
              <Example
                title="Bio de Instagram"
                description="Dentro de la bio de Instagram, como sitio web y contacto directo"
                url="https://instagram.com/vincenzzoshop"
              />
            </div>
          </div>
          <div className="swiper-slide">
            <div className="slide-content">
              <Example
                title="Historias de Instagram"
                description="Como conctacto directo a WhatsApp desde sitios web"
                img={eliRuiz}
              />
            </div>
          </div>
          <div className="swiper-slide">
            <div className="slide-content">
              <Example
                title="Linktr.ee"
                description="Como botón dentro de una página link in bio, como Linktree"
                url="https://linktr.ee/joyeriainter"
              />
            </div>
          </div>
          <div className="swiper-slide">
            <div className="slide-content">
              <Example
                title="Sitios Web"
                description="Como conctacto directo a WhatsApp desde sitios web"
                url="https://munitienda.com/"
              />
            </div>
          </div>
          <div className="swiper-slide">
            <div className="slide-content">
              <Example
                img={smsExample}
                description="Dentro de mensajes de texto"
                title="Mensajes de Texto"
              />
            </div>
          </div>

          <div className="swiper-slide">
            <div className="slide-content">
              <Example
                img={qrExample}
                description="En piezas gráficas con QR, para contacto directo a WhatsApp offline"
                title="Piezas Gráficas"
              />
            </div>
          </div>
          <div className="swiper-slide">
            <div className="slide-content">
              <Example
                title="Tiendas físicas"
                description="En QR para contacto directo desde tiendas físicas"
                img={storeFront}
              />
            </div>
          </div>
          <div className="swiper-slide">
            <div className="slide-content">
              <Example
                title="Tweets"
                description="Como punto de contacto a WhatsApp desde Twitter o redes sociales"
                url="https://twitter.com/Sandwich_qbano/status/1390000043197964299"
              />
            </div>
          </div>
          <div className="swiper-slide">
            <div className="slide-content">
              <Example
                title="Cuñas Radiales y/o comerciales de TV"
                description="Links personalizados de facil recordacion para publicidad en medios tradicionales"
              />
            </div>
          </div>
          {/* <div className="swiper-slide">
            <div className="slide-content">
              <h1>Recomendaciones:</h1>
            </div>
          </div> */}
          {/* <div className="swiper-slide">
            <div className="slide-content">
              <Recommendations />
            </div>
          </div> */}
          <div className="swiper-slide">
            <div className="slide-content">
              <h1>Y queremos ayudarte a vender con nuestras soluciones</h1>
            </div>
          </div>
          <div className="swiper-slide">
            <div className="slide-content">
              <FreeLinks />
            </div>
          </div>
          <div className="swiper-slide">
            <div className="slide-content">
              <PremiumLinks linkName={linkName} />
            </div>
          </div>
          <div className="swiper-slide">
            <div className="slide-content">
              <MultiagentLinks />
            </div>
          </div>
          <div className="swiper-slide">
            <div className="slide-content">
              <FormLinks />
            </div>
          </div>
          <div className="swiper-slide">
            <div className="slide-content">
              <div style={{ textAlign: "center" }}>
                <h2>¿Quieres saber más?</h2>
                <h2>
                  <a href="https://crear.wa.link">crear.wa.link</a>
                </h2>
              </div>
            </div>
          </div>
        </div>
        <div className="swiper-pagination"></div>
      </div>
      <div className="arrowContainer">
        {end ? (
          <Emoji symbol="👏" size={30} />
        ) : (
          <img
            onClick={() => nextSlide()}
            src={down}
            alt="down arrow"
            className="downArrow"
          />
        )}
      </div>
    </>
  );
}

export default App;

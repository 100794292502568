import React from "react";
import Emoji from "./Emoji";

const FormLinks = () => {
  return (
    <div className="serviceCard">
      <h2>
        Links de Formulario <Emoji symbol="🔥" size={25} />
      </h2>
      <p>
        Optimiza tu proceso de atención al cliente a través de WhatsApp con
        links de formulario.
      </p>
      <p>
        <Emoji symbol="✔️" /> Haz preguntas relevantes a tus usuario antes de
        que lleguen a tu chat con una página personalizable{" "}
      </p>
      <p>
        <Emoji symbol="✔️" />
        Los datos estarán listos para ser procesados una vez el usuario inicie
        la conversación.
      </p>
      <p className="price">
        <strong>$10 USD/mes por formulario</strong>
      </p>
    </div>
  );
};

export default FormLinks;
